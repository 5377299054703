<template>
  <el-pagination
    v-model:current-page="curPage"
    v-model:page-size="pageSize"
    :page-sizes="pageSizes"
    :background="background"
    :layout="layout"
    :total="total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>

<script setup name="Pagination">
import { computed } from 'vue'
const props = defineProps({
  page: { 
    type: Number, 
    default: 1 
  },
  size: { 
    type: Number, 
    default: 10 
  },
  total: { 
    type: Number, 
    default: 0 
  },
  layout: { 
    type: String, 
    default: 'total, prev, pager, next, sizes' 
  },
  pageSizes: {
    type: Array,
    default: () => [10, 20, 30, 50, 100]
  },
  background: {
    type: Boolean,
    default: true
  }
})

const emit = defineEmits(['update:size', 'update:page', 'pagination'])

const pageSize = computed({
  get: () => props.size,
  set: (val) => {
    emit('update:page', 1)
    emit('update:size', val)
  }
})
const curPage = computed({
  get: () => props.page,
  set: (val) => {
    emit('update:page', val)
  }
})

function handleSizeChange() {
  emit('pagination')
}

function handleCurrentChange() {
  emit('pagination')
}
</script>

<style lang="scss" scoped>

</style>