import axios from "axios";
import Cookies from "js-cookie";
import router from "../router/index";
import { PLATFROM_CONFIG } from "../../public/config";
import { ElMessage } from "element-plus";

const $http = axios.create({
  baseURL: PLATFROM_CONFIG.baseURL,
  timeout: 30000,
});

// 添加请求拦截器
$http.interceptors.request.use(
  function (config) {
    // 在发送请求之前,如果cookie为空，可以跳登录页面的
    config.headers["Content-Type"] = "application/json;charset=utf-8";
    //console.log(Cookies.get('name')+" cookide ");
    if (Cookies.get("name") == "") {
      router.push("/");
      return false;
    }
    if (Cookies.get("name")) {
      config.headers["Authorization"] = "Bearer " + Cookies.get("name");
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
$http.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    ElMessage.error({
      message: error.message,
      duration: 2000,
    });
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default $http;
