<template>
  <div class="returnCardBox">
    <!-- 筛选条件 -->
    <div class="searchBox">
      <el-row>
        <el-col :span="5" class="flex items-center">
          <div class="flex-none mr-8px">联系姓名:</div>
          <el-input v-model="queryParams.data.contactName" placeholder="请输入联系姓名" />
        </el-col>
        <el-col :span="5" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">联系手机号:</div>
          <el-input v-model="queryParams.data.contactMobile" placeholder="请输入手机号" />
        </el-col>
        <el-col :span="5" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">会员ID:</div>
          <el-input v-model="queryParams.data.memberId" placeholder="请输入会员ID" />
        </el-col>
        <el-col :span="5" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">退卡原因:</div>
          <el-select v-model="queryParams.data.reason" placeholder="请选择退卡原因">
            <el-option v-for="item in reasonList" :key="item.value" :label="item.key" :value="item.value" />
          </el-select>
        </el-col>
      </el-row>
      <el-row class="mt20 mb-20px">
        <el-col :span="5" class="flex items-center">
          <div class="flex-none mr-8px">处理状态:</div>
          <el-select v-model="queryParams.data.status" placeholder="请选择处理状态">
            <el-option v-for="item in statusList" :key="item.value" :label="item.key" :value="item.value" />
          </el-select>
        </el-col>
        <el-col :span="6" class="flex items-center ml-20px">
          <div class="flex-none mr-8px">提交日期:</div>
          <el-config-provider :locale="zhCn">
            <el-date-picker
              v-model="createTime"
              :default-time="[new Date('1 00:00:00'), new Date('1 23:59:59')]"
              end-placeholder="结束日期"
              start-placeholder="开始日期"
              type="daterange"
              value-format="YYYY-MM-DD HH:mm:ss"
              @change="timeChange"
            />
          </el-config-provider>
        </el-col>
        <el-col :span="2" class="ml-20px flex">
          <el-button type="primary" @click="getTabList">查 询</el-button>
          <el-button @click="resetTable">重 置</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="exportBox">
      <el-button @click="exportExcel" icon="el-icon-download" type="primary" size="small">导 出</el-button>
    </div>
    <!-- table表格 -->
    <el-table stripe :data="returnCardList" :header-cell-style="{ background: 'rgb(242, 242, 242)', color: '#333' }" v-loading="tableLoading" element-loading-text="拼命加载中...">
      <el-table-column label="序号" type="index" width="50" />
      <el-table-column prop="contactName" label="联系姓名" show-overflow-tooltip />
      <el-table-column prop="contactMobile" label="联系手机号" />
      <el-table-column prop="memberId" label="会员ID" show-overflow-tooltip />
      <el-table-column label="退卡原因" show-overflow-tooltip>
        <template #default="scope">
          <span v-if="scope.row.reason == 1">个人原因</span>
          <span v-if="scope.row.reason == 2">服务不佳</span>
          <span v-if="scope.row.reason == 3">产品或项目问题</span>
          <span v-if="scope.row.reason == 4">未达到预期效果</span>
        </template>
      </el-table-column>
      <el-table-column prop="creationTime" :formatter="formatCreationTime" label="提交时间" width="160" show-overflow-tooltip />
      <el-table-column label="状态">
        <template #default="scope">
          <span v-if="scope.row.status == 0"><el-tag type="warning">待处理</el-tag></span>
          <span v-if="scope.row.status == 1"><el-tag type="success">同意退卡</el-tag></span>
          <span v-if="scope.row.status == 2"><el-tag type="info">取消退卡</el-tag></span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template #default="scope">
          <el-button type="primary" size="small" @click="lookDetailFun('deal', scope.row)" v-if="scope.row.status == 0">处理</el-button>
          <el-button type="primary" plain size="small" @click="lookDetailFun('look', scope.row)" v-else>查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="paginationBox">
      <Pagination
        v-model:page="queryParams.data.page"
        v-model:size="queryParams.data.pageSize"
        :total="total"
        @pagination="getTabList"
      />
    </div>
  </div>
</template>

<script setup name="ReturnCard">
import { ref, reactive, onMounted } from 'vue'
import * as XLSX from "xlsx"
import { ElMessage } from "element-plus"
import { useRouter } from "vue-router"
import { returnList, returnCardExport } from "@/http/returnCard"
import { FullTimeFormat } from '@/utils/common'
import Pagination from '@/components/pagination/index.vue'
import zhCn from "element-plus/lib/locale/lang/zh-cn"

const now = new Date();
const date = now.toLocaleDateString().split('/');
date[1] < 10 ? date[1] = `0${date[1]}` : date[1] = date[1]
date[2] < 10 ? date[2] = `0${date[2]}` : date[2] = date[2]
let createTime = ref([`${date[0]}-${date[1]}-${date[2]} 00:00:00`, `${date[0]}-${date[1]}-${date[2]} 23:59:59`])
// 筛选条件
let queryParams = reactive({
  data: {
    contactName: '', // 联系姓名
    contactMobile: '', // 联系手机号
    memberId: '', // 会员ID
    reason: '', // 退卡原因
    status: '', // 处理状态
    submitTimeStart: createTime.value[0], // 开始日期
    submitTimeEnd:  createTime.value[1], // 结束日期
    page: 1,
    pageSize: 10
  }
})
let reasonList = ref([
  { key: '个人原因', value: 1 },
  { key: '服务不佳', value: 2 },
  { key: '产品或项目问题', value: 3 },
  { key: '未达到预期效果', value: 4 }
])
let statusList = ref([
  { key: '待处理', value: 0 },
  { key: '同意退卡', value: 1 },
  { key: '取消退卡', value: 2 }
])
function timeChange(value) {
  if(value) {
    queryParams.data.submitTimeStart = value[0]
    queryParams.data.submitTimeEnd = value[1]
  } else {
    queryParams.data.submitTimeStart = ''
    queryParams.data.submitTimeEnd = ''
  }
}
// table
let tableLoading = ref(false)
let returnCardList = ref([])
let total = ref(0)
function getTabList() {
  console.log(queryParams.data);
  tableLoading.value = true
  returnList(queryParams.data).then(res => {
    tableLoading.value = false
    returnCardList.value = res.items
    total.value = res.totalCount
  }).catch(() => {
    tableLoading.value = false
  })
}
function resetTable() {
  createTime.value = ''
  queryParams.data.contactName = ''
  queryParams.data.contactMobile = ''
  queryParams.data.memberId = ''
  queryParams.data.reason = ''
  queryParams.data.status = ''
  queryParams.data.submitTimeStart = ''
  queryParams.data.submitTimeEnd = ''
  queryParams.data.page = 1
  queryParams.data.pageSize = 10
  getTabList()
}
function formatCreationTime(row) {
  return FullTimeFormat(row.creationTime)
}
// 查看/处理
const router = useRouter()
function lookDetailFun(type, row) {
  router.push({ 
    name: "ReturnCardDetail", 
    query: {
      type: type,
      id: row.id
    } 
  })
}
//导出表格为excel
function exportExcel() {
  if(!queryParams.data.submitTimeStart || !queryParams.data.submitTimeEnd) return ElMessage.warning({
    message: '筛选日期不能为空',
    duration: 2000
  })
  exportData()
};
function exportData() {
  let data = {
    contactName: queryParams.data.contactName,
    contactMobile: queryParams.data.contactMobile,
    memberId: queryParams.data.memberId,
    reason: queryParams.data.reason,
    status: queryParams.data.status,
    submitTimeStart: queryParams.data.submitTimeStart,
    submitTimeEnd: queryParams.data.submitTimeEnd
  }
  returnCardExport(data).then((res) => {
    if(!res.eValues) return ElMessage.warning({
      message: '导出数据为空，请重新进行数据筛选',
      duration: 2000
    })
    let reg = /\\/g
    let replaceAfter = res.eValues.replace(reg, "")
    var jsondata = JSON.parse(replaceAfter)
    let time = new Date().getTime()
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(jsondata)
    XLSX.utils.book_append_sheet(workbook, worksheet, "data")
    XLSX.writeFile(workbook, `客户退卡列表_${time}.xlsx`)
  })
}
onMounted(() => {
  getTabList()
})
</script>

<style lang="scss" scoped>
.exportBox {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
::v-deep {
  .paginationBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next {
      padding: 8px !important;
    }
  }
}
</style>